<template src='./updateBackofficeUser.html'>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./updateBackofficeUser.css">
</style>

<script>
import  formBackofficeUserComponent  from '../formBackofficeUser';
import Crud from '../../../../service/Crud';
export default {

    data()
    {
        return{
          modal_open:false,
          service:null,
        }
    },
    created: function ()
    {
      this.$moment.locale('es');
      this.service = Crud('UserBo');
    },
    methods:
    {
        update(model)
        {
          this.service.findOne(model.dni)
          .then(
            model => {
              this.$refs.ModalParent.IsUpdated();
              this.$refs.ModalParent.setData(model.data);
             this.$refs.ModalParent.abrir_modal();
            }
          )


          return;
        },
        submit(model)
        {

          //el campo password viene oculto pero es necesario que lo tenga para la validación
          model.password_confirmation = ('password_confirmation' in model) ? model.password_confirmation :'';
          model.password = ('password' in model) ? model.password :'';

          // console.log(model);
          this.service.update(model.id,model)
            .then(() => {
              // console.log(us);
              this.submitData();
              this.$refs.ModalParent.cerrar_modal();
              });
        },
        submitData()
        {
            this.$emit('reset');
        }
    },

    components:
    {
      formBackofficeUserComponent
    }
}
</script>
