import { render, staticRenderFns } from "./BackOficeUserTable.html?vue&type=template&id=27edadc2&scoped=true&"
import script from "./BackOficeUserTable.vue?vue&type=script&lang=js&"
export * from "./BackOficeUserTable.vue?vue&type=script&lang=js&"
import style0 from "./BackOficeUserTable.css?vue&type=style&index=0&id=27edadc2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27edadc2",
  null
  
)

export default component.exports