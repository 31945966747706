import axios from 'axios'
import env from  '../envioments'
import removeNullable from "../tools/removeNullable";
export  class Crud {
    module;
    constructor(module) {
        this.module = module;
    }
    findAll( filter = {}) {
        filter = removeNullable(Object.assign({},filter));
        return axios.get(`${env.remoteUrl}/admin/${this.module}`, {params: filter})
            .then(res => res.data);
    }
    findOne(id) {
      return axios.get(`${env.remoteUrl}/admin/${this.module}/${id}`)
    }
    patch(id, append, data = {}) {
        return axios.patch(`${env.remoteUrl}/admin/${this.module}/${id}/${append}`, data)
            .then(res => res.data);
    }
    store(data = {}) {
        return axios.post(`${env.remoteUrl}/admin/${this.module}`, data)
    }
    delete(id) {
        return axios.delete(`${env.remoteUrl}/admin/${this.module}/${id}`)
    }
    update(id, data = {}) {
        return axios.put(`${env.remoteUrl}/admin/${this.module}/${id}`, data)
    }

}

export default (module) => {
    return new Crud(module);
};
