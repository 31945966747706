export default obj => {
    // tslint:disable-next-line:forin
    for (const member in obj) {
        if(obj.hasOwnProperty(member)) {
            if (obj[member] === null || obj[member] === '' || obj[member] === 'null' || obj[member] === undefined|| obj[member] === -1) {
                delete obj[member];
            }
        }

    }
    return obj;
};
