<template src='./BackOficeUserTable.html'>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./BackOficeUserTable.css">
</style>

<script>

import insertBackofficeUserComponent from './formBackOfficeUser/insertBackofficeUser/insertBackofficeUser';
import updateBackofficeUserComponent from './formBackOfficeUser/updateBackofficeUser/updateBackofficeUser';
import DeleteComponent from './Delete/Delete';
import ChangePasswordComponent from './ChangePassword/ChangePassword';
import Crud from '../../service/Crud';

export default {
    data()
    {
        return {
          filters: {
              page:1,
              perPage: 20,
          },
          totalRows: 0,

          service:null,
          data:[]
        }
    },
    created: function()
    {
      this.$moment.locale('es');
      this.service = Crud('UserBo');
      this.reload();
    },
    methods:{
        clearFilters() {
            const omit  = ['perPage', 'totalRows', 'page'];
            Object.keys(this.filters).map(((value) => { if( omit.indexOf(value) === -1){ this.filters[value] = null} }));
            this.reload();
        },
        insertar()
        {
            this.$refs.modalInsert.insert();
        },
        reload()
        {
          this.service.findAll(this.filters).then (res =>{
            this.data = res.data.data;
            // console.log(this.data);
          })
        },
        updateUser(model)
        {
          this.$refs.modalUpdate.update(model);
        },


        changeRoute(item){
          this.$router.push({ name: 'payment-tickers', params: { userId: item.id } })
        },

         OnDelete(item){
          this.$refs.Delete.openModal(item);
        },

        OnChangePassword(item)
        {
          this.$refs.ChangePassword.openModal(item);
        }

    },
    components:
    {
        insertBackofficeUserComponent,
        updateBackofficeUserComponent,
        DeleteComponent,
        ChangePasswordComponent

    }
}
</script>
