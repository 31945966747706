<template src='./formBackofficeUser.html'>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./formBackofficeUser.css">
</style>

<script>
import Dialog from 'primevue/dialog';
import { BFormInvalidFeedback } from 'bootstrap-vue';
import Multiselect from 'vue-multiselect'
import Utils from '../../../service/Utils'

export default {
    components:
    {
        Dialog, BFormInvalidFeedback, Multiselect
    },
    created: function ()
    {

    },
    data()
    {
        return{
            isCreated:false,
            modalform:false,
            selected_menu:[],
            model:{},
            menu:{}
        }
    },

    methods:
    {
      abrir_modal()
      {
        this.modalform = true;
      },
      cerrar_modal()
      {
        this.modalform = false;
      },
      //METODO GENERAL cuando se haga SUBMIT
      //los modales comparten ese método
      onSubmit()
      {
        this.$refs.observer.validate();
        if(!this.$refs.observer.flags.invalid){
            this.submitData();
        }
      },
      setData(model)
      {
        this.model = model;

        //this.selected_menu = this.model.menu.map((menu)=>{return menu.id});
        //console.log(this.selected_menu)
      },
      submitData()
      {
          this.$emit('dataOK',this.model);
      },
      IsCreated()
      {
        this.isCreated=true;
      },
      IsUpdated()
      {
        this.isCreated=false;
      },
      setMenu()
      {
         Utils.menu().
          then(m => {
            this.menu = m.data})
      },
      updateSelectedStyle (style) {
        this.model.menu = style
      },
      addTag (newTag) {
        const tag = {
          label: newTag,
          id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
        }
        this.model.menu.push(tag)
        this.menu.push(tag)
      }
    },
    mounted(){

      this.setMenu();

    },
    props:['title']
}
</script>
