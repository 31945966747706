<template src='./ChangePassword.html'>
</template>



<script>
import Dialog from 'primevue/dialog';
import { BFormInvalidFeedback } from 'bootstrap-vue';
import Crud from '../../../service/Crud';



export default {

   components:
    {
        Dialog, BFormInvalidFeedback
    },
    data()
    {
      return{
            selected:null,
            modalform:false,
            model:{},
        }
    },
    created: function()
    {
      this.$moment.locale('es');
      this.service = Crud('UserBo');

    },
    methods:{
    openModal(item)
      {
        this.selected = item;
        this.model = Object.assign({}, item);
        this.modalform = true;
      },
      onSubmit()
      {
        this.service.patch(this.selected.id,'changePassword',this.model).then ()
        {
          this.modalform = false;
          this.$toast.add({severity:'success', summary:"Cambio de contraseña correcta.", life: 3000});
          this.reload();
        }
      },

      reload()
      {
        this.$emit('reset');
      }
    },

}
</script>
