<template src='./insertBackofficeUser.html'>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./insertBackofficeUser.css">
</style>

<script>
import  formBackofficeUserComponent  from '../formBackofficeUser';
import Crud from '../../../../service/Crud';
export default {

    data()
    {
        return{

          service:null,
        }
    },
    created: function ()
    {
      this.$moment.locale('es');
      this.service = Crud('UserBo');
    },
    methods:
    {
        insert()
        {
          this.$refs.ModalParent.IsCreated();
          this.$refs.ModalParent.setData({});
          this.$refs.ModalParent.abrir_modal();
          return;
        },

        submit(model)
        {
          this.service.store(model)
            .then(() => {
              // console.log(us);
              this.submitData();
              this.$refs.ModalParent.cerrar_modal();
            });
        },

         submitData()
        {
            this.$emit('reset');
        }
    },
    components:
    {
      formBackofficeUserComponent
    }
}
</script>
